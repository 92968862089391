"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StyledDropdown", {
  enumerable: true,
  get: function get() {
    return _dropdown["default"];
  }
});
Object.defineProperty(exports, "StyledItem", {
  enumerable: true,
  get: function get() {
    return _item["default"];
  }
});
Object.defineProperty(exports, "StyledSearch", {
  enumerable: true,
  get: function get() {
    return _search["default"];
  }
});
exports.StyledCheckmarkIcon = exports.StyledScrollControls = exports.StyledCountryCodeItem = exports.StyledSearchContainer = exports.StyledUl = exports.StyledScrollable = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

var _checkmark = _interopRequireDefault(require("../../../Option/checkmark"));

var _dropdown = _interopRequireDefault(require("./dropdown"));

var _item = _interopRequireDefault(require("./item"));

var _search = _interopRequireDefault(require("./search"));

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 0.8rem;\n    height: 0.8rem;\n    line-height: 1;\n    margin-left: 0.75rem;\n    flex-shrink: 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: relative;\n    padding-top: 1rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 0 0 auto;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n    border-bottom: 1px solid ", ";\n    padding: 0 1rem;\n    margin-top: 5px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: relative;\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    box-sizing: border-box;\n    height: ", "px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0;\n    padding: 0;\n    max-height: 225px;\n    box-sizing: border-box;\n    overflow-y: auto;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledScrollable = _styledComponents["default"].div(_templateObject());

exports.StyledScrollable = StyledScrollable;

var StyledUl = _styledComponents["default"].ul(_templateObject2(), function (props) {
  return props.listHeight;
});

exports.StyledUl = StyledUl;
var StyledSearchContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject3(), function (props) {
  return props.palette.border.divider;
});
exports.StyledSearchContainer = StyledSearchContainer;
var StyledCountryCodeItem = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject4());
exports.StyledCountryCodeItem = StyledCountryCodeItem;

var StyledScrollControls = _styledComponents["default"].div(_templateObject5());

exports.StyledScrollControls = StyledScrollControls;
var StyledCheckmarkIcon = (0, _styledComponents["default"])(_checkmark["default"])(_templateObject6());
exports.StyledCheckmarkIcon = StyledCheckmarkIcon;