"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _paddings = require("../../../styles/paddings");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.875rem;\n    font-weight: 500;\n    line-height: 1.57;\n    color: $color-gray-4;\n    padding-left: ", ";\n    padding-right: ", ";\n    padding-bottom: 0.875rem;\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContent = _styledComponents["default"].div(_templateObject(), _paddings.PADDING_MEDIUM, _paddings.PADDING_X_LARGE, function (props) {
  return props.isCollapsed && 'display: none;';
});

var _default = StyledContent;
exports["default"] = _default;