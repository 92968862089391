"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MARGIN_XX_LARGE = exports.MARGIN_X_LARGE = exports.MARGIN_LARGE = exports.MARGIN_MEDIUM = exports.MARGIN_SMALL = exports.MARGIN_X_SMALL = exports.MARGIN_XX_SMALL = void 0;
var MARGIN_XX_SMALL = '0.25rem';
exports.MARGIN_XX_SMALL = MARGIN_XX_SMALL;
var MARGIN_X_SMALL = '0.5rem';
exports.MARGIN_X_SMALL = MARGIN_X_SMALL;
var MARGIN_SMALL = '0.75rem';
exports.MARGIN_SMALL = MARGIN_SMALL;
var MARGIN_MEDIUM = '1rem';
exports.MARGIN_MEDIUM = MARGIN_MEDIUM;
var MARGIN_LARGE = '1.5rem';
exports.MARGIN_LARGE = MARGIN_LARGE;
var MARGIN_X_LARGE = '2rem';
exports.MARGIN_X_LARGE = MARGIN_X_LARGE;
var MARGIN_XX_LARGE = '3rem';
exports.MARGIN_XX_LARGE = MARGIN_XX_LARGE;