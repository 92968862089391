"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLabel = exports.StyledInput = exports.StyledColor = exports.StyledColors = exports.StyledContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

var _hiddenElement = _interopRequireDefault(require("../../../Structural/hiddenElement"));

var _styled = require("../styled");

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: inline-block;\n    margin: 0 0.45rem;\n    width: 28px;\n    height: 28px;\n    padding: 0;\n    ", "\n\n    &:hover {\n        cursor: pointer;\n    }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    &:focus + label {\n        border: 1px solid ", ";\n        box-shadow: ", ";\n    }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    line-height: inherit;\n    height: inherit;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    text-align: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 0 0 auto;\n    padding: 0.5rem 0 0.25rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].div(_templateObject());

exports.StyledContainer = StyledContainer;

var StyledColors = _styledComponents["default"].div(_templateObject2());

exports.StyledColors = StyledColors;

var StyledColor = _styledComponents["default"].span(_templateObject3());

exports.StyledColor = StyledColor;
var StyledInput = (0, _attachThemeAttrs["default"])((0, _styledComponents["default"])(_hiddenElement["default"]))(_templateObject4(), function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.shadows.brand;
});
exports.StyledInput = StyledInput;
var StyledLabel = (0, _attachThemeAttrs["default"])(_styledComponents["default"].label)(_templateObject5(), _styled.CssCircleColor);
exports.StyledLabel = StyledLabel;