"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _paddings = require("../../../styles/paddings");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n            & > div {\n                padding-right: ", ";\n            }\n        "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-direction: ", ";\n    align-items: flex-start;\n    justify-content: flex-start;\n    ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContentContainer = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var orientation = _ref.orientation;
  return orientation === 'vertical' ? 'column' : 'row';
}, function (_ref2) {
  var orientation = _ref2.orientation;
  return orientation === 'horizontal' && (0, _styledComponents.css)(_templateObject2(), _paddings.PADDING_MEDIUM);
});

var _default = StyledContentContainer;
exports["default"] = _default;