"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: absolute;\n    overflow: hidden;\n    z-index: 2000;\n    left: 50%;\n    float: left;\n    width: 100%;\n    margin-top: 0.25rem;\n    margin-bottom: 0.25rem;\n    border: solid 1px ", ";\n    border-radius: 0.875rem;\n    padding: 0 0 1rem 0;\n    font-size: 0.75rem;\n    background: ", ";\n    box-shadow: ", ";\n    transform: translateX(-50%);\n    display: none;\n    opacity: 0;\n    transition: opacity 0.1s linear, visibility 0.1s linear;\n    visibility: hidden;\n    top: 100%;\n    ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledDropdown = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.border.divider;
}, function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.shadows.shadow_2;
}, function (props) {
  return props.isOpen && "\n            display: block;\n            opacity: 1;\n            transition: opacity 0.1s linear, visibility 0.1s linear;\n            visibility: visible;\n    ";
});
var _default = StyledDropdown;
exports["default"] = _default;