"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _poweredByGoogle = _interopRequireDefault(require("../icons/poweredByGoogle"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 0.725rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledPoweredByGoogleLogo = (0, _styledComponents["default"])(_poweredByGoogle["default"])(_templateObject());
var _default = StyledPoweredByGoogleLogo;
exports["default"] = _default;