"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    align-items: center;\n    color: ", ";\n    display: flex;\n    line-height: 1;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 1.25rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledIcon = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject(), function (props) {
  return props.palette.brand.main;
});
var _default = StyledIcon;
exports["default"] = _default;