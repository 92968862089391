"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPreview = exports.CssCircleColor = exports.CssInput = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _fontSizes = require("../../../styles/fontSizes");

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 40px;\n    height: 40px;\n    margin: 10px 0;\n    ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border-radius: 50%;\n    border: 1px solid ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    line-height: 1.5rem;\n    height: 1.5rem;\n    font-size: ", ";\n    text-align: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var CssInput = (0, _styledComponents.css)(_templateObject(), _fontSizes.FONT_SIZE_TEXT_SMALL);
exports.CssInput = CssInput;
var CssCircleColor = (0, _styledComponents.css)(_templateObject2(), function (props) {
  return props.palette.border.divider;
});
exports.CssCircleColor = CssCircleColor;
var StyledPreview = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject3(), CssCircleColor);
exports.StyledPreview = StyledPreview;