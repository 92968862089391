"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useFormatedWeekDay", {
  enumerable: true,
  get: function get() {
    return _useFormatedWeekDay["default"];
  }
});
Object.defineProperty(exports, "useFormattedNumberDay", {
  enumerable: true,
  get: function get() {
    return _useFormattedNumberDay["default"];
  }
});

var _useFormatedWeekDay = _interopRequireDefault(require("./useFormatedWeekDay"));

var _useFormattedNumberDay = _interopRequireDefault(require("./useFormattedNumberDay"));