"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledInput = exports.StyledButtonIcon = exports.StyledIconContainer = exports.StyledBackdrop = exports.TruncatedText = exports.StyledDropzone = exports.StyledContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

var _iconContainer = _interopRequireDefault(require("../../Input/styled/iconContainer"));

var _ButtonIcon = _interopRequireDefault(require("../../ButtonIcon"));

var _margins = require("../../../styles/margins");

var _borderRadius = require("../../../styles/borderRadius");

var _fontSizes = require("../../../styles/fontSizes");

var _paddings = require("../../../styles/paddings");

var _replaceAlpha = _interopRequireDefault(require("../../../styles/helpers/color/replaceAlpha"));

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    opacity: 0;\n\n    &:hover {\n        cursor: pointer;\n    }\n\n    ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: unset;\n    height: unset;\n    pointer-events: auto;\n    margin-right: 6px;\n    ", "\n\n    svg {\n        pointer-events: none;\n    }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    svg {\n        width: 24px !important;\n        height: 24px !important;\n        font-size: 24px !important;\n        color: ", ";\n\n        ", "\n\n        ", "\n    }\n\n    ", "\n\n    ", "\n    ", "\n\n    ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-left: 2.35rem;\n    padding-right: ", ";\n    border-radius: ", ";\n    pointer-events: none;\n\n    ", "\n\n    ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    max-width: 100%;\n    pointer-events: none;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["     \n    position: relative;\n    width: 100%;\n    height: 2.5rem;   \n    padding: 4px;\n    border: dashed 1px ", ";\n    border-radius: ", ";\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center; \n\n    &:hover {\n        cursor: pointer;\n        border: dashed 1px ", ";\n    }\n\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n    \n    ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].div(_templateObject());

exports.StyledContainer = StyledContainer;
var StyledDropzone = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject2(), function (props) {
  return props.palette.text.disabled;
}, _borderRadius.BORDER_RADIUS_2, function (props) {
  return props.palette.background.disabled;
}, function (props) {
  return props.palette.text.main;
}, function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.hasFocus && "\n        outline: 0;\n        border: solid 1px ".concat(props.palette.brand.main, ";\n        box-shadow: ").concat(props.shadows.brand, ";\n    ");
}, function (props) {
  return props.variant === 'multiline' && "\n        min-height: 12rem;\n        border-radius: 27px;\n        height: 100%;\n        width: 100%;\n    ";
}, function (props) {
  return props.disabled && "\n        color: ".concat(props.palette.text.disabled, ";\n\n        &:hover {\n            cursor: not-allowed;\n            border-color: ").concat(props.palette.text.disabled, ";\n        }\n    ");
}, function (props) {
  return props.error && "\n        color: ".concat(props.palette.error.main, ";\n        border: dashed 1px ").concat(props.palette.error.main, ";\n        background-color: ").concat((0, _replaceAlpha["default"])(props.palette.error.main, 0.1), ";\n\n        &:hover {\n            border: dashed 1px ").concat(props.palette.error.main, ";\n        }\n\n        ").concat(props.hasFocus && "\n            outline: 0;\n            border: solid 1px ".concat(props.palette.error.main, ";\n            box-shadow: ").concat(props.shadows.error, ";\n            "), "\n        ");
}, function (props) {
  return props.isDragOver && "\n        color: ".concat(props.palette.brand.main, ";\n        background-color: ").concat((0, _replaceAlpha["default"])(props.palette.brand.main, 0.05), ";\n        border-color: ").concat(props.palette.brand.main, ";\n    ");
});
exports.StyledDropzone = StyledDropzone;

var TruncatedText = _styledComponents["default"].span(_templateObject3());

exports.TruncatedText = TruncatedText;
var StyledBackdrop = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject4(), _paddings.PADDING_MEDIUM, _borderRadius.BORDER_RADIUS_2, function (props) {
  return props.isFileSelected && "\n        justify-content: left;\n        background-color: ".concat(props.palette.background.highlight, ";\n        padding-right: 2.35rem;\n        ");
}, function (props) {
  return props.variant === 'multiline' && "\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        text-align: center;\n        font-size: ".concat(_fontSizes.FONT_SIZE_TEXT_MEDIUM, ";\n        padding: ").concat(_paddings.PADDING_MEDIUM, ";\n        background: transparent;\n        height: 100%;\n        width: 100%;\n        ");
});
exports.StyledBackdrop = StyledBackdrop;
var StyledIconContainer = (0, _attachThemeAttrs["default"])((0, _styledComponents["default"])(_iconContainer["default"]))(_templateObject5(), function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.error && "\n            color: ".concat(props.palette.error.main, "\n        ");
}, function (props) {
  return props.disabled && "\n            color: ".concat(props.palette.text.disabled, ";\n        ");
}, function (props) {
  return props.isSingleFile && props.variant === 'inline' && "\n        svg {\n            width: 18px !important;\n            height: 18px !important;\n            font-size: 18px !important;\n        }\n    ";
}, function (props) {
  return props.iconPosition === 'left' && "\n        left: ".concat(props.readOnly ? 0 : '0.8rem', ";\n    ");
}, function (props) {
  return props.iconPosition === 'right' && "\n        right: ".concat(props.readOnly ? 0 : '0.3rem', ";\n    ");
}, function (props) {
  return props.variant === 'multiline' && "\n        position: static;\n        width: 64px;\n        height: auto;\n        margin-bottom: ".concat(_margins.MARGIN_X_SMALL, ";\n        svg {\n            width: 64px !important;\n            height: 64px !important;\n            font-size: 64px !important;\n\n            ").concat(props.error && "\n                height: 40px !important;\n                width: 40px !important;\n                font-size: 40px !important;\n                color: ".concat(props.palette.error.main, ";\n            "), "\n        }\n    ");
});
exports.StyledIconContainer = StyledIconContainer;
var StyledButtonIcon = (0, _styledComponents["default"])(_ButtonIcon["default"])(_templateObject6(), function (props) {
  return props.pickerVariant === 'multiline' && "\n        position: absolute;\n        top: 12px;\n        right: 2px;\n    ";
});
exports.StyledButtonIcon = StyledButtonIcon;

var StyledInput = _styledComponents["default"].input(_templateObject7(), function (props) {
  return props.disabled && "\n        &:hover {\n            cursor: not-allowed;\n        }\n    ";
});

exports.StyledInput = StyledInput;