"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PADDING_XX_LARGE = exports.PADDING_X_LARGE = exports.PADDING_LARGE = exports.PADDING_MEDIUM = exports.PADDING_SMALL = exports.PADDING_X_SMALL = exports.PADDING_XX_SMALL = void 0;
var PADDING_XX_SMALL = '0.25rem';
exports.PADDING_XX_SMALL = PADDING_XX_SMALL;
var PADDING_X_SMALL = '0.5rem';
exports.PADDING_X_SMALL = PADDING_X_SMALL;
var PADDING_SMALL = '0.75rem';
exports.PADDING_SMALL = PADDING_SMALL;
var PADDING_MEDIUM = '1rem';
exports.PADDING_MEDIUM = PADDING_MEDIUM;
var PADDING_LARGE = '1.5rem';
exports.PADDING_LARGE = PADDING_LARGE;
var PADDING_X_LARGE = '2rem';
exports.PADDING_X_LARGE = PADDING_X_LARGE;
var PADDING_XX_LARGE = '3rem';
exports.PADDING_XX_LARGE = PADDING_XX_LARGE;