"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getPreviewDataToImport;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable no-restricted-globals */
function getDateValue(value) {
  var date = new Date(value);
  var isValidDate = !isNaN(date.getTime());
  return isValidDate ? value : '';
}

function getTypeValue(value, dataType) {
  switch (dataType.name) {
    case 'String':
      return value;

    case 'Number':
      return !isNaN(Number(value)) ? value : '';

    case 'Boolean':
      return value ? 'true' : 'false';

    case 'Date':
      return getDateValue(value);

    default:
      return value || '';
  }
}

function getNormalizedFieldValue(value, attributeDef) {
  if (typeof attributeDef === 'function') {
    return getTypeValue(value, attributeDef);
  }

  if (typeof attributeDef.type === 'function') {
    return getTypeValue(value, attributeDef.type);
  }

  return value || '';
}

function getPreviewDataToImport(data, fieldsMap, attributes) {
  return data.map(function (item) {
    return _objectSpread({}, Object.keys(fieldsMap).reduce(function (acc, field) {
      var keys = fieldsMap[field].split(',');
      var value = keys.reduce(function (accumulator, key) {
        return "".concat(accumulator, " ").concat(item[key] || '').trim();
      }, '') || attributes[field].defaultTo;
      return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, field, getNormalizedFieldValue(value, attributes[field])));
    }, {}));
  });
}