"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledInput = exports.StyledContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _pickerInput = _interopRequireDefault(require("../../Input/pickerInput"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    text-transform: capitalize;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].div(_templateObject());

exports.StyledContainer = StyledContainer;
var StyledInput = (0, _styledComponents["default"])(_pickerInput["default"])(_templateObject2());
exports.StyledInput = StyledInput;