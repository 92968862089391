"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isNumeric;

function isNumeric(value) {
  if (value) {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(value);
  }

  return false;
}