"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: relative;\n    border-top: 2px solid ", ";\n    pointer-events: none;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledLine = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.error.main;
});
var _default = StyledLine;
exports["default"] = _default;