"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchValueIconColor = exports.IconPinColor = exports.IconCircleColor = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

require("../../../styles/defaultTheme");

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    fill: ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    fill: ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    fill: ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var IconCircleColor = (0, _attachThemeAttrs["default"])(_styledComponents["default"].svg)(_templateObject(), function (props) {
  return props.palette.text.disabled;
});
exports.IconCircleColor = IconCircleColor;
var IconPinColor = (0, _attachThemeAttrs["default"])(_styledComponents["default"].svg)(_templateObject2(), function (props) {
  return props.palette.background.main;
});
exports.IconPinColor = IconPinColor;
var SearchValueIconColor = (0, _attachThemeAttrs["default"])(_styledComponents["default"].svg)(_templateObject3(), function (props) {
  return props.palette.brand.main;
});
exports.SearchValueIconColor = SearchValueIconColor;