"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledStepsList = exports.StyledContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: inline-flex;\n    flex-direction: column;\n    align-items: center;\n    position: relative;\n    box-sizing: border-box;\n    padding: 0.25rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].nav(_templateObject());

exports.StyledContainer = StyledContainer;

var StyledStepsList = _styledComponents["default"].div(_templateObject2());

exports.StyledStepsList = StyledStepsList;