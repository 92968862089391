"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Default", {
  enumerable: true,
  get: function get() {
    return _default["default"];
  }
});
Object.defineProperty(exports, "ColorsFixed", {
  enumerable: true,
  get: function get() {
    return _colorsFixed["default"];
  }
});

var _default = _interopRequireDefault(require("./default"));

var _colorsFixed = _interopRequireDefault(require("./colorsFixed"));