"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

var _input = _interopRequireDefault(require("../../Input/styled/input"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding-right: 2.35rem;\n    font-weight: 400;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n\n    :hover {\n        cursor: pointer;\n    }\n\n    :focus,\n    :active {\n        padding-right: 2.35rem;\n    }\n\n    &[disabled] {\n        cursor: not-allowed;\n    }\n\n    ", "\n    ", "\n    ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var PickerInput = (0, _attachThemeAttrs["default"])((0, _styledComponents["default"])(_input["default"]))(_templateObject(), function (props) {
  return props.variant === 'shaded' && "\n        box-shadow: ".concat(props.shadows.shadow_10, ";\n        border: 1px solid transparent;\n        ").concat(props.error && "\n            border: 2px solid ".concat(props.palette.error.main, ";\n        "), "\n        ").concat(props.readOnly && "\n            box-shadow: none;\n        ", "\n    ");
}, function (props) {
  return props.variant === 'bare' && "\n        :hover {\n            box-shadow: ".concat(props.shadows.shadow_10, ";\n            background-color: ").concat(props.palette.background.main, ";\n        }\n    ");
}, function (props) {
  return props.isReadOnly && !props.disabled && "\n        box-shadow: none;\n        background-color: transparent;\n        border: 2px solid transparent;\n        user-select: none;\n        pointer-events: none;\n        padding-left: 0;\n        padding-right: 0;\n        outline: 0;\n\n        ".concat(props.icon && "\n            border: 1px solid transparent;\n            padding-left: 2.35rem;\n        ", ";        \n\n        &::-ms-expand {\n            display: none;\n        }\n\n        :focus,\n        :active {\n            padding-left: 0;\n            padding-right: 0;\n            outline: 0;\n            box-shadow: none;\n            border-color: transparent;\n            background-color: transparent;\n\n            ").concat(props.icon && "\n                border: 1px solid transparent;\n                padding-left: 2.35rem;\n            ", "\n        }\n    ");
});
var _default = PickerInput;
exports["default"] = _default;