"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

var _fontSizes = require("../../../styles/fontSizes");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0;\n    padding: 0;\n    font-weight: inherit;\n    color: ", ";\n    font-size: ", ";\n    box-sizing: border-box;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledLabel = (0, _attachThemeAttrs["default"])(_styledComponents["default"].h1)(_templateObject(), function (props) {
  return props.palette.text.main;
}, _fontSizes.FONT_SIZE_HEADING_SMALL);
var _default = StyledLabel;
exports["default"] = _default;