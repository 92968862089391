"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _fontSizes = require("../../../styles/fontSizes");

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: inline-block;\n    background-color: transparent;\n    color: ", ";\n    text-align: center;\n    font-size: ", ";\n    font-weight: 400;\n    line-height: 38px;\n    height: 38px;\n    margin: 6px auto;\n    cursor: not-allowed;\n    user-select: none;\n\n    @media (max-width: 600px) {\n        margin: 3px auto;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledDayAdjacent = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject(), function (props) {
  return props.palette.text.disabled;
}, _fontSizes.FONT_SIZE_TEXT_MEDIUM);
var _default = StyledDayAdjacent;
exports["default"] = _default;