"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var merge = function merge() {
  // create a new object
  var target = {}; // deep merge the object into the target object

  var merger = function merger(obj) {
    var keys = Object.keys(obj);
    keys.forEach(function (prop) {
      if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
        // if the property is a nested object
        target[prop] = merge(target[prop], obj[prop]);
      } else {
        // for regular property
        target[prop] = obj[prop];
      }
    });
  }; // iterate through all objects and
  // deep merge them with target


  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  args.forEach(function (obj) {
    if (obj) {
      merger(obj);
    }
  });
  return target;
};

var _default = merge;
exports["default"] = _default;