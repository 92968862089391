'use strict'

var convert = require('hast-util-is-element/convert')

module.exports = convert([
  'audio',
  'canvas',
  'embed',
  'iframe',
  'img',
  'math',
  'object',
  'picture',
  'svg',
  'video'
])
