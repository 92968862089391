"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FONT_SIZE_TEXT_LARGE = exports.FONT_SIZE_TEXT_MEDIUM = exports.FONT_SIZE_TEXT_SMALL = exports.FONT_SIZE_TEXT_X_SMALL = exports.FONT_SIZE_HEADING_X_LARGE = exports.FONT_SIZE_HEADING_LARGE = exports.FONT_SIZE_HEADING_MEDIUM = exports.FONT_SIZE_HEADING_SMALL = void 0;
// font sizes heading
var FONT_SIZE_HEADING_SMALL = '1rem';
exports.FONT_SIZE_HEADING_SMALL = FONT_SIZE_HEADING_SMALL;
var FONT_SIZE_HEADING_MEDIUM = '1.25rem';
exports.FONT_SIZE_HEADING_MEDIUM = FONT_SIZE_HEADING_MEDIUM;
var FONT_SIZE_HEADING_LARGE = '1.5rem';
exports.FONT_SIZE_HEADING_LARGE = FONT_SIZE_HEADING_LARGE;
var FONT_SIZE_HEADING_X_LARGE = '2rem'; // font sizes text

exports.FONT_SIZE_HEADING_X_LARGE = FONT_SIZE_HEADING_X_LARGE;
var FONT_SIZE_TEXT_X_SMALL = '0.625rem';
exports.FONT_SIZE_TEXT_X_SMALL = FONT_SIZE_TEXT_X_SMALL;
var FONT_SIZE_TEXT_SMALL = '0.75rem';
exports.FONT_SIZE_TEXT_SMALL = FONT_SIZE_TEXT_SMALL;
var FONT_SIZE_TEXT_MEDIUM = '0.875rem';
exports.FONT_SIZE_TEXT_MEDIUM = FONT_SIZE_TEXT_MEDIUM;
var FONT_SIZE_TEXT_LARGE = '1rem';
exports.FONT_SIZE_TEXT_LARGE = FONT_SIZE_TEXT_LARGE;