"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    line-height: inherit;\n    height: inherit;\n\n    :not(:last-child) {\n        margin-right: 0.25rem;\n    }\n\n    display: ", ";\n    transition: transform 300ms ease-in-out;\n\n    &:hover {\n        transform: scale(1.5);\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledStartContainer = _styledComponents["default"].span(_templateObject(), function (props) {
  return props.readOnly ? '' : 'inline-block';
});

var _default = StyledStartContainer;
exports["default"] = _default;